<script>
import Swal from 'sweetalert2'
import VIcon from '@/components/Icon.vue'
import PassCard from '@/components/PassCard.vue'
// import card1 from '@/assets/img/card1.png'
// import card2 from '@/assets/img/card2.png'
// import card3 from '@/assets/img/card3.png'
// import card7 from '@/assets/img/card7.png'
// import card9 from '@/assets/img/card9.png'
// import card10 from '@/assets/img/card10.png'
import reloadImage from '@/assets/img/load.png'

// import newCard1 from '@/assets/img/newCardBG/card1.png'
// import newCard2 from '@/assets/img/newCardBG/card2.png'
// import newCard4 from '@/assets/img/newCardBG/card4.png'
// import newCard5 from '@/assets/img/newCardBG/card5.png'

export default {
  components: {
    VIcon,
    PassCard,
  },
  props: {
    card: Object,
    cardId: String,
    cardNumber: String,
    showActions: {
      type: Boolean,
      default: true,
    },
    showBalance: {
      type: Boolean,
      default: true
    },
    showCanceledCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReloadingBalance: false,
      balance: [],
      fullBalance: 0,
      cardData: {},
      loading: false,
      showVisaLogo: true,
      showPass: false,
      cardBg: {
        1: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard1.png?alt=media',
        2: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard2.png?alt=media',
        3: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard3.png?alt=media',
        4: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard1.png?alt=media',
        7: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard7.png?alt=media',
        9: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard9.png?alt=media',
        10: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2Fcard10.png?alt=media'
      },
      newCardBg: {
        1: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2FnewCard1.png?alt=media',
        2: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2FnewCard2.png?alt=media',
        4: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2FnewCard4.png?alt=media',
        5: 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2FnewCard5.png?alt=media'
      },
      cardBgImage: '',
      cardTextColor: '',
      reloadImage,
      realCardData: {
        data: {
          cvv2: ''
        },
        number: [
          '****',
          '****',
          '****',
          '****'
        ],
        show: false
      }
    };
  },
  computed: {
    devAccount() {
      return this.$store.state.user.devAccount;
    },
    name() {
      if (this.cardData.nomeImpresso) return this.cardData.nomeImpresso;
      return 'OnlyPay';
    },
    number() {
      if (this.cardData.numeroCartao) return this.cardData.numeroCartao.match(/.{4}/g);
      return ['0000', '****', '****', '0000'];
    },
    validThru() {
      if (this.cardData.dataValidade) return `${this.$moment(this.cardData.dataValidade).format('MM/YY')}`;
      return '00/00';
    },
    shouldShowCard() {
      if (this.$route.path === '/bank-transfer') {
        return this.card.idProduto !== 2 && this.card.idProduto !== 5 && this.card.idProduto !== 7 && this.card.idProduto !== 8 && this.card.idProduto !== 9 && this.card.idProduto !== 10 && (this.card.idStatusCartao !== 2 && this.card.idStatusCartao !== 3 && this.card.idStatusCartao !== 4 && this.card.idStatusCartao !== 5 && this.card.idStatusCartao !== 6 && this.card.idStatusCartao !== 7 && this.card.idStatusCartao !== 8 && this.card.idStatusCartao !== 9 && this.card.idStatusCartao !== 10 && this.card.idStatusCartao !== 11 && this.card.idStatusCartao !== 12 && this.card.idStatusCartao !== 13 && this.card.idStatusCartao !== 14 && this.card.idStatusCartao !== 15 && this.card.idStatusCartao !== 16 && this.card.idStatusCartao !== 17) && this.fullBalance > 0;
      } if (this.$route.path === '/recarga-celular') {
        return (this.card.idStatusCartao !== 2 && this.card.idStatusCartao !== 3 && this.card.idStatusCartao !== 4 && this.card.idStatusCartao !== 5 && this.card.idStatusCartao !== 6 && this.card.idStatusCartao !== 7 && this.card.idStatusCartao !== 8 && this.card.idStatusCartao !== 9 && this.card.idStatusCartao !== 10 && this.card.idStatusCartao !== 11 && this.card.idStatusCartao !== 12 && this.card.idStatusCartao !== 13 && this.card.idStatusCartao !== 14 && this.card.idStatusCartao !== 15 && this.card.idStatusCartao !== 16 && this.card.idStatusCartao !== 17) && this.fullBalance > 0;
      } if (this.$route.path === '/extrato-pagamento') {
        return (this.card.idStatusCartao !== 2 && this.card.idStatusCartao !== 3 && this.card.idStatusCartao !== 4 && this.card.idStatusCartao !== 5 && this.card.idStatusCartao !== 6 && this.card.idStatusCartao !== 7 && this.card.idStatusCartao !== 8 && this.card.idStatusCartao !== 9 && this.card.idStatusCartao !== 10 && this.card.idStatusCartao !== 11 && this.card.idStatusCartao !== 12 && this.card.idStatusCartao !== 13 && this.card.idStatusCartao !== 14 && this.card.idStatusCartao !== 15 && this.card.idStatusCartao !== 16 && this.card.idStatusCartao !== 17);
      }
      return ((this.card.idStatusCartao === 3 && this.showCanceledCard) || this.card.idStatusCartao !== 3);
    },
  },
  watch: {
    card: {
      immediate: true,
      handler(value) {
        if (value) {
          this.cardData = this.card;
        }
      }
    },
    cardData: {
      immediate: true,
      async handler(value) {
        if (value.id && !this.balance.length && !this.isReloadingBalance) {
          if (value.flagVirtual) await this.getRealDataFromVirtualCard(value.id)
          await this.reloadBalance(value)
        }
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.getImageCardByApi();
    if (this.cardId) {
      this.$http.get(`/cards/${this.cardId}`)
        .then(({ data }) => {
          this.cardData = data;
        });
    }
    if (this.cardNumber) {
      this.$http.get(`/cards/listar/numero?numeroCartao=${this.cardNumber}`)
        .then(({ data }) => {
          [this.cardData] = data.content;
        });
    }
    if (this.$route.name === 'my-cards') {
      this.reloadBalance()
    }
  },
  beforeUpdate() {
    // Se houverem cartões
    if (this.$refs.card) {
      if (this.$refs.card && this.cardBgImage) {
        this.$store.commit('pageLoading', false);
      }

      const availableCards = Object.values(this.$refs.card).filter(ref => ref !== undefined);

      if (availableCards.length) {
        return this.$store.commit('setAvailableCards', true);
      }
      return this.$store.commit('setAvailableCards', false);
    }
    // Se não houverem cartões
    if (this.$refs.card === undefined && this.cardBgImage) {
      this.$store.commit('pageLoading', false);
    }
    return this.$store.commit('setAvailableCards', false);
  },
  methods: {
    getCardBg(myCard) {
      if (myCard.flagVirtual) {
        return this.cardBg[3];
      }
      if (this.$moment(myCard.dataEmissao).isSameOrAfter('2021-07-28T00:00:00.000Z') && myCard.idProduto > 0 && myCard.idProduto < 6) {
        this.showVisaLogo = false;
        return this.newCardBg[(myCard && myCard.idProduto)] || this.cardBg[1];
      }
      return this.cardBg[(myCard && myCard.idProduto)] || this.cardBg[1];
    },
    blockCard() {
      this.$store.commit('setCurrentCard', this.cardData);
      this.$store.commit('toggleBlockCard');
    },
    cardClick() {
      if (this.fullBalance || !this.fullBalance) this.$emit('click', this.cardData, this.fullBalance);
    },
    goToRecharge() {
      this.$router.push({ name: 'rechargeData', params: { idCartao: this.cardData.id.toString() } });
    },
    requestNewCard() {
      this.$store.commit('setCurrentCard', this.cardData);
      this.$store.commit('toggleRequestNewCard');
    },
    async reloadBalance(value) {
      if (!value.id) return null;
      this.isReloadingBalance = true;
      await this.$http.get(`${process.env.VUE_APP_HUB_BASE_URL2}/cards/${value.id}/saldo`)
        .then(({ data }) => {
          this.isReloadingBalance = false;
          this.fullBalance = data.saldoDisponivelGlobal;

          const solucao = (this.setMoneyMask(data.saldoDisponivelGlobal) || '').split(',');
          this.balance = [
            (this.fullBalance > 0 ? solucao[0] : '0'),
            (this.fullBalance > 0 ? solucao[1] : '00')
          ]
          if (value.idProduto === 7 && data.saldoDisponivelGlobal === 0 && !localStorage.messageRenault && !value.flagVirtual) {
            Swal.fire({
              title: 'Parabéns :)',
              html: `<p style="text-align: left; font-size: 16px;">Premiado da Campanha La pagadora, parabéns pelo seu prêmio. Informamos que após o desbloqueio do seu cartão seu prêmio será disponibilizado em até 48 horas (úteis).<br><br>
                Devido as políticas de segurança de entregas dos cartões pelos correios ou transportadoras, não é permitido o transporte dos cartões com saldos dos prêmios. <br><br>
                Para duvidas com relação a valores divergentes do prêmio recebido, favor entrar em contato com o SAC Renault 0800.0555615.<br><br>
                <strong style="font-size: 14px">Obs: Os cartões da promoção La Pagadora não efetuam Saques, somente operações de compras (Através da Bandeira Visa) em lojas físicas e online, na opção Credito à vista.<strong><p>`,
              confirmButtonText: 'Ok'
            })
            localStorage.messageRenault = true
          }
        }).catch(async () => {
          this.isReloadingBalance = false;
          // await this.reloadBalance(this.cardData);
        });
    },
    async getRealDataFromVirtualCard(id) {
      await this.$http.get(`/cards/${id}/real-card-data`)
        .then(({ data }) => {
          const { show } = this.realCardData
          this.realCardData.data = data
          this.realCardData.number = data.numeroCartao.match(/.{4}/g)
          this.realCardData.show = show
        });
    },
    currencyFormatter(value) {
      if (!Number(value)) return '';
      const amount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL' }).format(value / 100);
      return `${amount}`.slice(3);
    },
    floatCurrency(value) {
      const amount = value.toString()
      if (amount.includes('.')) {
        let newValue = '';
        if (amount.split('.')[1].length > 1) {
          newValue = this.currencyFormatter(parseInt(amount.replace('.', ''), 10))
        } else {
          newValue = this.currencyFormatter(parseInt(`${amount.replace('.', '')}0`, 10))
        }
        return newValue;
      }
      return this.currencyFormatter(parseInt(`${amount}00`, 10))
    },
    setMoneyMask(value = 0) {
      return (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })).replace('R$', '').trim();
    },
    async getImageCardByApi() {
      const { id } = this.cardData || this.card;
      if (!id) return null;
      this.$store.commit('pageLoading', true);

      await this.$http.get(`/products/list/${id}`)
        .then(({ data }) => {
          if (data[0].imagePathUrl) {
            this.cardBgImage = data[0].imagePathUrl;
          } else {
            this.cardBgImage = 'https://firebasestorage.googleapis.com/v0/b/onlypay-prd.appspot.com/o/cartao%2FnewCard2.png?alt=media';
          }
          this.cardTextColor = data[0].corTextCartao;
        })
        .catch(() => {
          this.$store.commit('pageLoading', false);
        })
        .then(() => {
          // setTimeout(() => {
          //   this.$store.commit('pageLoading', false);
          // }, 1000);
        })
      // console.log('imagem nova', this.cardBgImage, 'imagem atual', this.getCardBg(this.cardData), 'São iguais?', this.cardBgImage === this.getCardBg(this.cardData));
    }
  },
};
</script>

<template>
  <div ref="card" :class="{ 'clickable': $listeners.click }"
    class="card" v-if="shouldShowCard">
    <div
      :class="{ 'card__virtual' : this.card.flagVirtual && this.cardTextColor == null }" class="card__balance on"
      :style="{color: `${cardTextColor}`}">
      <!-- <div
        class="card__balance__text"
        v-if="showBalance">
        Saldo Atual
      </div> -->
      <transition name="card__balance__value"  v-if="showBalance">
        <div class="card__balance__value">
          <span class="card__balance__value__prefix">R$</span>
          {{balance[0] || '0'}}<span class="card__balance__value__decimal">,{{balance[1] || '00' }}</span>
          <v-icon v-if="!isReloadingBalance" style="cursor: pointer; margin-bottom: 2px; margin-left: 13px; width: 19px; height: 19px;"  class="refresh-icon__figure__toggle " name="refresh-icon" @click="reloadBalance(cardData)" />
          <v-icon v-if="isReloadingBalance" style="margin-bottom: 2px; margin-left: 13px; width: 19px; height: 19px;" class="refresh-icon__figure__toggle  rotating-refresh-icon" name="refresh-icon" />
          <!-- <span class="d-block card__balance__reload" @click="reloadBalance(cardData)">Atualizar saldo<img v-if="isReloadingBalance" style="width: 19px;" :src="reloadImage" class="rotating" /></span> -->
        </div>
      </transition>
    </div>
    <div class="warning__pass" v-if="cardData.idStatusCartao > 3 && (this.card.idProduto == 9 || this.card.idProduto == 10) && cardData.idStatusCartao != 29 && cardData.idStatusCartao != 200">Para a utilização do cartão é necessário alterar a senha. Utilize a funcionalidade ALTERAR SENHA DO CARTÃO indicada abaixo da imagem do cartão.</div>
    <div class="warning__pass" v-if="cardData.idStatusCartao === 3">Este cartão está cancelado e não pode ser usado para realizar transações.</div>
    <div
      @click="cardClick"
      :class="{ 'opacity-5' : cardData.idStatusCartao > 2, 'card__virtual' : this.card.flagVirtual && this.cardTextColor == null}"
      class="card__figure card__figure cardbg"
      :style="{backgroundImage: `url(${cardBgImage})`, color: `${cardTextColor}`}">
      <!-- :style="{backgroundImage: `url(${getCardBg(this.cardData || this.card)})`}"> -->
      <!-- <v-icon v-if="!this.card.flagVirtual && this.card.idProduto !== 9 && this.card.idProduto !== 10 && this.showVisaLogo" class="card__figure__flag" name="visa-logo"/> -->
      <div class="card__figure__data">
        <div class="card__figure__data__number pointer" @click="realCardData.show = (!realCardData.show && card.flagVirtual)">
          <span class="card__figure__data__number__group">{{number[0]}}</span>
          <span class="card__figure__data__number__group">{{realCardData.show ? realCardData.number[1] : number[1]}}</span>
          <span class="card__figure__data__number__group">{{realCardData.show ? realCardData.number[2] : number[2]}}</span>
          <span class="card__figure__data__number__group">{{number[3]}}</span>
          <v-icon v-if="validThru !== '00/00' && card.flagVirtual" class="card__figure__data__number__toggle" :name="realCardData.show ? 'view' : 'hide'"/>
        </div>
        <div class="card__figure__data__valid-thru">
          {{validThru}}<span style="padding:0 5%" v-if="realCardData.show">CVV {{realCardData.data.cvv2}}</span>
        </div>
        <div class="card__figure__data__name">
          {{name}}
        </div>
      </div>
    </div>
    <!-- TODO: Adicionando validações apenas para remover espaço extra quando não tem nenhuma opção disponivel -->
    <div v-if="showActions && (cardData.idStatusCartao < 3 || cardData.idStatusCartao > 3 || cardData.idStatusCartao < 4 && !cardData.flagVirtual)" class="card__actions">
      <div v-if="false" class="card__actions__action">
        <a @click="goToRecharge" class="card__actions__action--recharge">
          <v-icon class="card__actions__action__icon" name="recharge"/>
          <span class="card__actions__action__text">Recarregar</span>
        </a>
      </div>
      <div v-if="cardData.idStatusCartao !== 3" class="card__actions__action row">
        <a
          v-if="cardData.idStatusCartao === 1"
          @click="blockCard"
          class="card__actions__action--blocked col-6">
          <v-icon class="card__actions__action__icon" name="lock"/>
          <span class="card__actions__action__text">Bloquear</span>
        </a>
        <a
          v-else-if="cardData.idStatusCartao === 2"
          @click="blockCard"
          class="card__actions__action--unblocked card__actions__action__unlock__pulse col-6">
          <v-icon class="card__actions__action__icon" name="unlock"/>
          <span class="card__actions__action__text">Desbloquear</span>
        </a>
        <a v-else-if="cardData.idStatusCartao > 3" class="card__actions__action--blocked col-12">
          <span class="card__actions__action__text">Cartão bloqueado {{cardData.idStatusCartao === 29 ? 'por erro de senha. Contate a Central de Atendimento para solicitar o desbloqueio.' : ''}} {{cardData.idStatusCartao === 200 ? 'devido a tentiva de fraude. Contate a Central de Atendimento para solicitar o desbloqueio.' : ''}} {{cardData.idStatusCartao != 29 && cardData.idStatusCartao != 200 ? ': Código ' + cardData.idStatusCartao : ''}}</span>
        </a>
        <a :class="'card__actions__action--blocked ' + (cardData.idStatusCartao === 3 ? 'col-12': 'line-left') " v-if="cardData.idStatusCartao < 4"></a>
        <a class="card__actions__action--blocked col-5" v-if="cardData.idStatusCartao < 4 && !cardData.flagVirtual" @click="requestNewCard">
          <span class="card__actions__action__text">Solicitar 2º Via</span>
        </a>
      </div>
    </div>
    <a
      v-if="showActions && cardData.idStatusCartao !== 3 && !this.card.cartaoVirtual"
      @click.prevent="showPass = true"
      class="card__actions__action--unblocked">
      <!-- <v-icon class="card__actions__action__icon" name="unlock"/> -->
      <span class="card__actions__action__text">Alterar Senha do Cartão</span>
    </a>

    <transition v-if="showPass" name="fade">
      <PassCard
        :idProduto="this.card.idProduto"
        :idStatusCartao="this.cardData.idStatusCartao"
        @close="showPass = false"/>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.opacity-5 {
  opacity: .5;
}
.pointer {
  cursor: pointer;
}
.card {
  .line-left {
    border-left: 1px #0000005e solid;
  }
  .cardbg {
    background-size: cover;
    background-position: center;
  }
  color: var(--color-white);
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  position: relative;
  transition: all 250ms;
  &.clickable {
    ^&__figure {
      cursor: pointer;
    }
    &:hover {
      box-shadow: 0 0 0 5px rgba(203, 207, 211, .5);
      transform: scale(1.02);
      text-decoration: none;
    }
  }
  &__balance {
    position: absolute 3.8rem auto auto 1.5rem;
    z-index: 100;
    &.on {
      ^&__text__toggle {
        transform: rotate(0deg);
      }
    }
    &__text {
      cursor: pointer;
      font-size: .75rem;
      font-weight: bold;
      text-transform: uppercase;
      &__toggle {
        margin: -.125rem 0 0 .25rem;
        size: .5rem;
        transform: rotate(-180deg);
      }
    }
    &__reload {
      font-size:.5em;
      color:grey;
      background-color: aliceblue;
      border-radius: 5px;
      padding: 0px 5px;
      cursor: pointer;
      &:hover {
        background-color: #e8e8e8;
      }
      &:active {
        background-color: #e0e0e0;
      }
    }
    &__value {
      font-size: 1.5rem;
      font-weight: bold;
      &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(-1rem);
      }
      &-enter-to, &-leave {
        opacity: 1;
        transform: translateY(0%);
      }
      &-enter-active, &-leave-active {
        transition: all 750ms;
      }
      &__prefix {
        display: inline-block;
        font-size: .5em;
        font-weight: bold;
        text-transform: uppercase;
        padding-top: 1.083em;
        margin-right: -.1666em;
      }
      &__decimal {
        display: inline-block;
        font-size: .75em;
        font-weight: bold;
        /* margin-left: .25rem; */
        text-transform: uppercase;
        padding-top: .3888em;
      }
    }
  }
  &__figure {
    /* background-image: linear-gradient(90deg, #bcff8f, #7fd148); */
    background: linear-gradient(135deg, var(--color-green-light), var(--color-blue-lighten));
    border-radius: .5rem;
    color: var(--color-white);
    cursor: default;
    display: block;
    overflow: hidden;
    padding: 30% 1.5rem 1.5rem;
    position: relative;
    transition: all 250ms;
    width: 100%;
    &:hover {
      text-decoration: none;
    }
    &:after {
      /* background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .4) 100%);
      border-radius: .5rem;
      content: '';
      position: absolute 30% -30% auto auto;
      size: 100%;
      transform: rotate(-30deg); */
    }
    &__flag {
      color: var(--color-white);
      position: absolute auto 1.5rem 1.5rem auto;
      size: 3rem .9731rem;
      z-index: 10;
    }
    &__data {
      font-family: 'Ocr';
      margin-bottom: -.25rem;
      &__number {
        margin-bottom: .25rem;
        &__group {
          /* vertical-align: middle; */
          &:not(:last-of-type) {
            margin-right: .5rem;
          }
        }
        &__toggle {
          margin: -.125rem 0 0 .50rem;
          size: 1.5rem;
          transform: rotate(-180deg);
        }
      }
      &__valid-thru {
        font-size: .75rem;
        margin-bottom: .25rem;
      }
      &__name {
        display: block;
        display: -webkit-box;
        font-size: .75rem;
        height: 1.1875rem;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      @media screen and (max-width: 735px) and (min-width:575px){
        margin-top: 20px;
      }
      @media screen and (max-width: 371px) {
        margin-top: 30px;
      }
    }
  }
  &__actions {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-light);
    border-radius: 0 0 .5rem .5rem;
    border-top: none;
    color: var(--color-black);
    display: flex;
    margin-top: -.5rem;
    flex-direction: row;
    padding: 1rem 0 .5rem;
    &:empty {
      display: none;
    }
    &__action {
      flex-grow: 1;
      padding: .5rem 1.5rem;
      text-align: center;
      width: 100%;
      &:last-of-type {
        border-left: 1px solid var(--color-grey-lighten);
      }
      &--recharge {
        color: var(--color-black);
      }
      &--blocked {
        color: var(--color-red);
      }
      &--unblocked {
        color: var(--color-green-dark);
      }
      &__icon {
        margin-right: .5rem;
        size: 1.25rem;
      }
      &__unlock__pulse {
        animation: shadow-pulse 1.8s infinite;
        border-radius: 4px;
        padding: 4px
      }
      &__text {
        font-size: .625rem;
        font-weight: bold;
        line-height: 1.25rem;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
  }
  &__virtual {
    color: #1c1b1b;
  }
  .warning__pass {
    position: absolute;
    z-index: 1;
    background-color: #cacacc;
    top: 5px;
    left: 125px;
    margin-right: 5px;
    color: #000;
    border:  1px solid #00a749;
    font-size: 11px;
    padding: 5px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
  }
}
//cursor: pointer; margin-top: 1px; margin-left: 13px; width: 19px; height: 19px;
.refresh-icon {
  &__figure {
    &__toggle {
      margin: -.125rem 0 .3rem .50rem;
      size: 1.5rem;
      transform: rotate(-180deg);
    }
    @media screen and (max-width: 735px) and (min-width:575px){
        margin-top: 20px;
    }
    @media screen and (max-width: 371px) {
      margin-top: 30px;
    }
  }
}

.rotating-refresh-icon {
  animation: rotating-refresh-icon 1s infinite linear;
}

@keyframes rotating-refresh-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotation 1s infinite linear;
}
@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 167, 73, 0.2);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(0, 167, 73, 0);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
