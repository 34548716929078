var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShowCard
    ? _c(
        "div",
        {
          ref: "card",
          staticClass: "card",
          class: { clickable: _vm.$listeners.click },
        },
        [
          _c(
            "div",
            {
              staticClass: "card__balance on",
              class: {
                card__virtual:
                  this.card.flagVirtual && this.cardTextColor == null,
              },
              style: { color: `${_vm.cardTextColor}` },
            },
            [
              _vm.showBalance
                ? _c(
                    "transition",
                    { attrs: { name: "card__balance__value" } },
                    [
                      _c(
                        "div",
                        { staticClass: "card__balance__value" },
                        [
                          _c(
                            "span",
                            { staticClass: "card__balance__value__prefix" },
                            [_vm._v("R$")]
                          ),
                          _vm._v("\n        " + _vm._s(_vm.balance[0] || "0")),
                          _c(
                            "span",
                            { staticClass: "card__balance__value__decimal" },
                            [_vm._v("," + _vm._s(_vm.balance[1] || "00"))]
                          ),
                          !_vm.isReloadingBalance
                            ? _c("v-icon", {
                                staticClass: "refresh-icon__figure__toggle",
                                staticStyle: {
                                  cursor: "pointer",
                                  "margin-bottom": "2px",
                                  "margin-left": "13px",
                                  width: "19px",
                                  height: "19px",
                                },
                                attrs: { name: "refresh-icon" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reloadBalance(_vm.cardData)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isReloadingBalance
                            ? _c("v-icon", {
                                staticClass:
                                  "refresh-icon__figure__toggle rotating-refresh-icon",
                                staticStyle: {
                                  "margin-bottom": "2px",
                                  "margin-left": "13px",
                                  width: "19px",
                                  height: "19px",
                                },
                                attrs: { name: "refresh-icon" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.cardData.idStatusCartao > 3 &&
          (this.card.idProduto == 9 || this.card.idProduto == 10) &&
          _vm.cardData.idStatusCartao != 29 &&
          _vm.cardData.idStatusCartao != 200
            ? _c("div", { staticClass: "warning__pass" }, [
                _vm._v(
                  "Para a utilização do cartão é necessário alterar a senha. Utilize a funcionalidade ALTERAR SENHA DO CARTÃO indicada abaixo da imagem do cartão."
                ),
              ])
            : _vm._e(),
          _vm.cardData.idStatusCartao === 3
            ? _c("div", { staticClass: "warning__pass" }, [
                _vm._v(
                  "Este cartão está cancelado e não pode ser usado para realizar transações."
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "card__figure card__figure cardbg",
              class: {
                "opacity-5": _vm.cardData.idStatusCartao > 2,
                card__virtual:
                  this.card.flagVirtual && this.cardTextColor == null,
              },
              style: {
                backgroundImage: `url(${_vm.cardBgImage})`,
                color: `${_vm.cardTextColor}`,
              },
              on: { click: _vm.cardClick },
            },
            [
              _c("div", { staticClass: "card__figure__data" }, [
                _c(
                  "div",
                  {
                    staticClass: "card__figure__data__number pointer",
                    on: {
                      click: function ($event) {
                        _vm.realCardData.show =
                          !_vm.realCardData.show && _vm.card.flagVirtual
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "card__figure__data__number__group" },
                      [_vm._v(_vm._s(_vm.number[0]))]
                    ),
                    _c(
                      "span",
                      { staticClass: "card__figure__data__number__group" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.realCardData.show
                              ? _vm.realCardData.number[1]
                              : _vm.number[1]
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "card__figure__data__number__group" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.realCardData.show
                              ? _vm.realCardData.number[2]
                              : _vm.number[2]
                          )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "card__figure__data__number__group" },
                      [_vm._v(_vm._s(_vm.number[3]))]
                    ),
                    _vm.validThru !== "00/00" && _vm.card.flagVirtual
                      ? _c("v-icon", {
                          staticClass: "card__figure__data__number__toggle",
                          attrs: {
                            name: _vm.realCardData.show ? "view" : "hide",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "card__figure__data__valid-thru" }, [
                  _vm._v("\n        " + _vm._s(_vm.validThru)),
                  _vm.realCardData.show
                    ? _c("span", { staticStyle: { padding: "0 5%" } }, [
                        _vm._v("CVV " + _vm._s(_vm.realCardData.data.cvv2)),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "card__figure__data__name" }, [
                  _vm._v("\n        " + _vm._s(_vm.name) + "\n      "),
                ]),
              ]),
            ]
          ),
          _vm.showActions &&
          (_vm.cardData.idStatusCartao < 3 ||
            _vm.cardData.idStatusCartao > 3 ||
            (_vm.cardData.idStatusCartao < 4 && !_vm.cardData.flagVirtual))
            ? _c("div", { staticClass: "card__actions" }, [
                false
                  ? _c("div", { staticClass: "card__actions__action" }, [
                      _c(
                        "a",
                        {
                          staticClass: "card__actions__action--recharge",
                          on: { click: _vm.goToRecharge },
                        },
                        [
                          _c("v-icon", {
                            staticClass: "card__actions__action__icon",
                            attrs: { name: "recharge" },
                          }),
                          _c(
                            "span",
                            { staticClass: "card__actions__action__text" },
                            [_vm._v("Recarregar")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.cardData.idStatusCartao !== 3
                  ? _c("div", { staticClass: "card__actions__action row" }, [
                      _vm.cardData.idStatusCartao === 1
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "card__actions__action--blocked col-6",
                              on: { click: _vm.blockCard },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "card__actions__action__icon",
                                attrs: { name: "lock" },
                              }),
                              _c(
                                "span",
                                { staticClass: "card__actions__action__text" },
                                [_vm._v("Bloquear")]
                              ),
                            ],
                            1
                          )
                        : _vm.cardData.idStatusCartao === 2
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "card__actions__action--unblocked card__actions__action__unlock__pulse col-6",
                              on: { click: _vm.blockCard },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "card__actions__action__icon",
                                attrs: { name: "unlock" },
                              }),
                              _c(
                                "span",
                                { staticClass: "card__actions__action__text" },
                                [_vm._v("Desbloquear")]
                              ),
                            ],
                            1
                          )
                        : _vm.cardData.idStatusCartao > 3
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "card__actions__action--blocked col-12",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "card__actions__action__text" },
                                [
                                  _vm._v(
                                    "Cartão bloqueado " +
                                      _vm._s(
                                        _vm.cardData.idStatusCartao === 29
                                          ? "por erro de senha. Contate a Central de Atendimento para solicitar o desbloqueio."
                                          : ""
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.cardData.idStatusCartao === 200
                                          ? "devido a tentiva de fraude. Contate a Central de Atendimento para solicitar o desbloqueio."
                                          : ""
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.cardData.idStatusCartao != 29 &&
                                          _vm.cardData.idStatusCartao != 200
                                          ? ": Código " +
                                              _vm.cardData.idStatusCartao
                                          : ""
                                      )
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.cardData.idStatusCartao < 4
                        ? _c("a", {
                            class:
                              "card__actions__action--blocked " +
                              (_vm.cardData.idStatusCartao === 3
                                ? "col-12"
                                : "line-left"),
                          })
                        : _vm._e(),
                      _vm.cardData.idStatusCartao < 4 &&
                      !_vm.cardData.flagVirtual
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "card__actions__action--blocked col-5",
                              on: { click: _vm.requestNewCard },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "card__actions__action__text" },
                                [_vm._v("Solicitar 2º Via")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.showActions &&
          _vm.cardData.idStatusCartao !== 3 &&
          !this.card.cartaoVirtual
            ? _c(
                "a",
                {
                  staticClass: "card__actions__action--unblocked",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showPass = true
                    },
                  },
                },
                [
                  _c("span", { staticClass: "card__actions__action__text" }, [
                    _vm._v("Alterar Senha do Cartão"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showPass
            ? _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("PassCard", {
                    attrs: {
                      idProduto: this.card.idProduto,
                      idStatusCartao: this.cardData.idStatusCartao,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showPass = false
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }