<script>
import VButton from '@/components/Button.vue';
import VIcon from '@/components/Icon.vue';
import VTextbox from '@/components/Textbox.vue';

export default {
  components: {
    VButton,
    VIcon,
    VTextbox,
  },
  data() {
    return {
      password: '',
    };
  },
  computed: {
    cardData() {
      return this.$store.state.currentCard;
    },
    devAccount() {
      return this.$store.state.user.devAccount;
    },
    message() {
      return `Cartão ${this.cardData.idStatusCartao === 1 ? 'bloqueado' : 'desbloqueado'} com sucesso.`;
    },
    text() {
      return this.cardData.idStatusCartao === 1
        ? 'Você deseja bloquear o seu cartão?'
        : 'Ao desbloquear será possível efetuar compras e fazer recargas.';
    },
    title() {
      return `${this.cardData.idStatusCartao === 1 ? 'Bloquear' : 'Desbloquear'} cartão`;
    },
    dataOptions() {
      // return this.cardData.idStatusCartao === 1
      //   ? '/cards/bloquear?id_status=2&observacao=Interface B2C'
      //   : '/cards/desbloquear'
      let body = {}
      let url = ''
      if (this.cardData.idStatusCartao === 1) {
        body = {
          cardid: this.cardData.id,
          id_status: 2,
          observacao: 'Interface B2C'
        }
        url = '/cards/bloquear'
      } else {
        body = { cardid: this.cardData.id }
        url = '/cards/desbloquear'
      }
      return { body, url }
    }
  },
  methods: {
    close() {
      this.$store.commit('toggleBlockCard');
    },
    blockCard() {
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true);
        this.$validator.validateAll().then((valid) => {
          const { body, url } = this.dataOptions
          if (valid) {
            this.$http.post(url, body)
              .then(({ data }) => {
                if (data.message) {
                  this.$store.commit('message', { type: 'error', text: data.message });
                } else {
                  this.password = '';
                  this.$store.commit('updateCard', { ...this.cardData, ...data });
                  this.$store.commit('message', { type: 'info', text: this.message });
                }
                this.$store.commit('toggleBlockCard');
                this.$store.commit('formLoading', false);
              });
          } else {
            this.$store.commit('formLoading', false);
          }
        });
      }
    },
  },
};
</script>

<template>
  <transition v-if="$store.state.blockCard" name="block">
    <div class="block">
      <form @submit.prevent="blockCard" class="block__form">
        <a @click="close" class="block__form__close">
          <v-icon class="block__form__close__icon" name="close"/>
        </a>
        <v-icon class="block__form__icon" name="block-card"/>
        <h1 class="block__form__title">{{title}}?</h1>
        <p class="block__form__info">{{text}}</p>
        <v-textbox
          v-model="password"
          class="block__form__password"
          label="Insira sua senha de acesso"
          name="password"
          placeholder="••••••••"
          type="password"
          v-if="!devAccount"
          validate="required"/>
        <div class="block__form__actions">
          <v-button v-bind:label="title" class="block__form__actions__submit"/>
          <a @click="close" class="block__form__actions__back">
            Não, voltar
          </a>
        </div>
      </form>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.block {
  position: fixed 0 0 0 0;
  z-index: 9999;
  @media (min-width: 992px) {
    align-items: center;
    background-color: rgba(0, 11, 23, .5);
    display: flex;
    justify-content: center;
  }
  &-enter-active {
    transition: all 250ms ease-in;
  }
  &-leave-active {
    transition: all 250ms ease-out;
  }
  &-enter, &-leave-to {
    opacity: 0;
    /* transform: translateX(100%);
    @media (min-width: 992px) {
      transform: translateY(-100%);
    } */
  }
  &__form {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    padding: 1.5rem;
    position: relative;
    @media (min-width: 992px) {
      align-items: center;
      border-radius: .5rem;
      height: auto;
      padding: 1.5rem 5rem;
      text-align: center;
      width: 34rem;
    }
    &__close {
      color: var(--color-grey-medium);
      position: absolute 1.5rem 1.5rem auto auto;
      size: 1rem;
      &__icon {
        display: block;
      }
    }
    &__icon {
      color: var(--color-black);
      size: 5rem;
    }
    &__title {
      color: var(--color-black);
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1.5rem 0;
    }
    &__info {
      font-size: .875rem;
    }
    &__password {
      margin: 2rem 0;
    }
    &__actions {
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      width: 100%;
      /* @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      } */
      &__submit {
        background-color: var(--color-red);
      }
      &__back {
        color: var(--color-black);
        display: block;
        margin-top: 1rem;
        padding: 1rem 0 .75rem;
        /* @media (min-width: 992px) {
          margin-right: .75rem;
          margin-top: 2rem;
          width: 10rem;
        } */
      }
    }
  }
}
</style>
