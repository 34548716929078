<script>
import VButton from './Button.vue';
import VIcon from './Icon.vue';
import VTextbox from './Textbox.vue';

export default {
  props: {
    idProduto: Number,
    idStatusCartao: Number
  },
  components: {
    VButton,
    VIcon,
    VTextbox,
  },
  data() {
    return {
      password: '',
      cardPassword: '',
      cardNumber: '',
    };
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changePass() {
      if (this.$store.state.formLoading) return false;
      this.$store.commit('formLoading', true);
      // check password
      this.$http.post('/users/check-user-logged', {
        senha: this.password,
      }).then(({ data }) => {
        if (data.check !== 'Ok') return false;
        this.$http({
          method: 'PUT',
          // url: `/cards/senha/alterar-senha?numeroCartao=${this.cardNumber.replace(/\s/g, '')}`,
          url: '/cards/senha/alterar-senha',
          data: {
            numeroCartao: this.cardNumber.replace(/\s/g, ''),
            senha: this.cardPassword,
          },
        })
          .then(dataCard => {
            const { statusCodeValue, body } = dataCard.data;
            if (statusCodeValue !== 200) {
              this.$store.commit('message', { type: 'error', text: body });
            } else {
              this.$store.commit('message', { type: 'info', text: 'Senha alterada com sucesso' });
              this.password = '';
              this.cardPassword = '';
              this.cardNumber = '';

              if ((this.idProduto === 9 || this.idProduto === 10) && this.idStatusCartao >= 2) {
                this.$parent.blockCard()
              }
              this.close();
            }
          }, err => {
            this.$store.commit('message', { type: 'error', text: err });
          });
      });
    },
  },
};
</script>

<template>
    <div class="block">
      <form @submit.prevent="changePass" class="block__form">
        <a @click="close" class="block__form__close">
          <v-icon class="block__form__close__icon" name="close"/>
        </a>
        <!-- <v-icon class="block__form__icon" name="block-card"/> -->
        <h1 class="block__form__title">Alterar senha do Cartão?</h1>
        <p class="block__form__info">Informe a senha de acesso ao site para criar uma nova senha para seu cartão.</p>
        <p class="block__form__info" v-if="this.idProduto === 9 || this.idProduto === 10">Ao alterar a senha do cartão, realize o desbloqueio para utilizá-lo</p>
        <div class="row">
          <div class="col-md-12">
            <v-textbox
              v-model="password"
              class="block__form__password"
              label="Insira a senha de acesso ao site"
              name="password"
              placeholder="••••••••"
              required
              type="password"/>
          </div>
          <div class="col-md-12">
            <v-textbox
              v-model="cardNumber"
              class="block__form__password"
              label="Insira o número do cartão"
              name="password"
              placeholder="•••• •••• •••• ••••"
              required
              mask="#### #### #### ####"
              type="text"/>
          </div>
        </div>
        <v-textbox
          v-model="cardPassword"
          class="block__form__password"
          label="Crie a nova senha para seu cartão (Deve conter 4 números)"
          name="password"
          maxlength="4"
          placeholder="••••"
          required
          type="password"/>
        <div class="block__form__actions">
          <v-button label="Alterar Senha" class="block__form__actions__submit"/>
          <a @click="close" class="block__form__actions__back">
            Não, voltar
          </a>
        </div>
      </form>
    </div>
</template>

<style lang="postcss" scoped>
.block {
  color: var(--color-grey-dark);
  position: fixed 0 0 0 0;
  z-index: 300;
  @media (min-width: 678px) {
    align-items: center;
    background-color: rgba(0, 11, 23, .5);
    display: flex;
    justify-content: center;
  }
  .row{
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    @media (max-width: 992px) {
      display: block;
    }
    .col-md-6{
      padding-left: 15px;
      padding-right: 15px;
      width: 50%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
  &__form {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
    overflow: scroll;
    justify-content: space-evenly;
    padding: 1.3rem 1rem;
    position: relative;
    @media (min-width: 992px) {
      align-items: center;
      border-radius: .5rem;
      height: auto;
      padding: 1.5rem 2rem;
      text-align: center;
      width: 34rem;
    }
    &__close {
      color: var(--color-grey-medium);
      position: absolute 1.5rem 1.5rem auto auto;
      size: 1rem;
      &__icon {
        display: block;
      }
    }
    &__icon {
      color: var(--color-black);
      size: 5rem;
    }
    &__title {
      color: var(--color-black);
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1.5rem 0;
    }
    &__info {
      font-size: .875rem;
      text-align: left;
    }
    &__password {
      margin: 2rem 0;
    }
    &__actions {
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      width: 100%;
      &__submit {
        background-color: var(--color-red);
      }
      &__back {
        color: var(--color-black);
        display: block;
        margin-top: 1rem;
        padding: 1rem 0 .75rem;
      }
    }
  }
}
</style>
