<!-- eslint-disable -->

<script>
import axios from 'axios';
import VButton from '@/components/Button.vue';
import VIcon from '@/components/Icon.vue';
import VTextbox from '@/components/Textbox.vue';

export default {
  components: {
    VButton,
    VIcon,
    VTextbox,
  },
  data() {
    return {
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
        number: '',
        neiborhood: '',
        zipCode: ''
      },
      userData: {},
      reason: '',
      reasonSelected: '',
      selectOptions: [
        'Perdi o cartão',
        'Furtaram/roubaram meu cartão',
        'Cartão está com problemas ao usar nas maquininhas',
        'Outro'
      ]
    };
  },
  computed: {
    cardData() {
      return this.$store.state.currentCard;
    },
    devAccount() {
      return this.$store.state.user.devAccount;
    },
    message() {
      return `Segunda via de cartão solicitada com sucesso.`;
    },
    text() {
      return this.cardData.idStatusCartao === 1
        ? 'Para solicitar uma segunda via de cartão, você precisa manter seu cartão bloqueado.'
        : 'Mantenha seu cartão bloqueado até a emissão da 2º via.';
    },
    price() {
      return this.cardData.idProduto === 9 || this.cardData.idProduto === 10
        ? 'A segunda via do seu cartão é gratuita.'
        : 'A solicitação do seu cartão tem um custo de R$32,00 e será debitado diretamente do saldo do seu cartão após a emissão.';
    },
    prize() {
      return 'O prazo para chegada do novo cartão é de 15 dias úteis.';
    },
    title() {
      return 'Solicitar 2º via de Cartão';
    },
  },
  methods: {
    close() {
      this.$store.commit('toggleRequestNewCard');
    },
    requestNewCard() {
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true);
        if(this.address.zipCode.length === 8) {
          this.address.zip = this.address.zipCode.substring(0, 5);
          this.address.neiborhood = this.address.zipCode.substring(5, 8);
        }
        if(this.address.street.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o nome da rua' })
            this.$store.commit('formLoading', false);
          return null;
        }

        if(this.address.number.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o número da residencia' })
            this.$store.commit('formLoading', false);
          return null;
        }

        if(this.address.city.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o nome da cidade' })
            this.$store.commit('formLoading', false);
          return null;
        }

        if(this.address.state.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o nome do estado' })
            this.$store.commit('formLoading', false);
          return null;
        }

        if(this.address.zip.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o CEP' })
            this.$store.commit('formLoading', false);
          return null;
        }

        if(this.address.neiborhood.length === 0) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o bairro' })
          this.$store.commit('formLoading', false);
          return null;
        }

        if(this.reason.length === 0 && !this.reasonSelected) {
          this.$store.commit('message', { type: 'error', text: 'Preencha o motivo da solicitação' })
          this.$store.commit('formLoading', false);
          return null;
        }

        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.$http
              .post('/cards/segunda-via', {
                cardMasked: this.cardData.numeroCartao,
                address: this.address,
                reason: (this.reason? this.reason : this.reasonSelected)
              })
              .then(({ data }) => {
                this.$store.commit('message', {
                  type: 'info',
                  text: 'Segunda via de cartão solicitada com sucesso.',
                });
                this.$store.commit('toggleRequestNewCard');
                this.$store.commit('formLoading', false);
              });
          } else {
            this.$store.commit('formLoading', false);
          }
        });
      }
    },
    cepInput(e) {
      const { value } = e.target;
      const cep = value.replace(/\D/g, '');
      if (cep) {
        if (/^[0-9]{8}$/.test(cep)) {
          axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(({ data }) => {
              this.address.state = data.uf;
              this.address.city = data.localidade;
              this.address.neiborhood = data.bairro;
              this.address.street = data.logradouro;
              this.address.zip = cep;
            });
        }
      }
    },
    houseNumber(num) {
      const { value } = num.target;
      const number = value.replace(/[^/SNsn0123456789]+/g, '');
      this.address.houseNumber = number;
    },
  },
  mounted() {
    //this.userData = this.$store.state.user;

    setTimeout(() => {
      this.userData = JSON.parse(localStorage.getItem('user'));

      this.address = {
        street: (this.userData.enderecos.logradouro) ? this.userData.enderecos.logradouro : '',
        city: (this.userData.enderecos.cidade) ? this.userData.enderecos.cidade : '',
        state: this.userData.enderecos.uf || this.userData.enderecos.estado || '',
        zip: (this.userData.enderecos.cep) ? this.userData.enderecos.cep : '',
        number: (this.userData.enderecos.numero) ? this.userData.enderecos.numero : '',
        neiborhood: (this.userData.enderecos.bairro) ? this.userData.enderecos.bairro : '',
        zipCode: (this.userData.enderecos.cep) ? this.userData.enderecos.cep : '',
        complement: (this.userData.enderecos.complemento) ? this.userData.enderecos.complemento : ' ',
      };
    }, 2000);
  }
};
</script>

<template>
  <transition v-if="$store.state.requestNewCard" name="block">
    <div class="block">
      <form @submit.prevent="requestNewCard" class="block__form">
        <a @click="close" class="block__form__close">
          <v-icon class="block__form__close__icon" name="close" />
        </a>
        <v-icon class="block__form__icon" name="block-card" />
        <h1 class="block__form__title">{{ title }}</h1>
        <p class="block__form__info mb-5 text-left">
          {{ text }}<br />
          {{ price }}<br />
          {{ prize }}
        </p>
        <p class="mb-4"><strong>Por favor, confirme o endereço de entrega do cartão.</strong></p>
        <div class="address">
          <div class="user-account__step__fieldset row">
            <v-textbox
              v-model="address.zipCode"
              v-on:keyup.native="cepInput"
              data-vv-as="CEP"
              data-vv-scope="account"
              label="CEP:"
              class="col-md-4"
              mask="#####-###"
              name="account.cep"
              placeholder="00000-000"
              type="tel"
              validate="required|cep"
              required
            />
            <v-textbox
              v-model="address.state"
              data-vv-as="Estado"
              data-vv-scope="account"
              label="Estado:"
              class="col-md-4"
              name="account.state"
              placeholder="Selecione"
              type="text"
              validate="required|max:2"
              required
            />
            <v-textbox
              v-model="address.city"
              data-vv-as="Cidade"
              data-vv-scope="account"
              label="Cidade:"
              class="col-md-4"
              name="account.city"
              type="text"
              validate="required"
              required
            />
            <v-textbox
              v-model="address.neiborhood"
              data-vv-as="Bairro"
              data-vv-scope="account"
              label="Bairro:"
              name="account.neighborhood"
              type="text"
              class="col-md-6"
              validate="required"
              required
            />
            <v-textbox
              v-model="address.street"
              data-vv-as="Logradouro"
              data-vv-scope="account"
              label="Logradouro:"
              name="account.street"
              type="text"
              class="col-md-6"
              validate="required"
              required
            />
            <v-textbox
              v-model="address.number"
              data-vv-as="Número"
              data-vv-scope="account"
              v-on:keyup.native="houseNumber"
              label="Número:"
              class="col-md-6"
              name="account.number"
              type="text"
              required
            />
            <v-textbox
              v-model="address.complement"
              data-vv-as="Complemento"
              data-vv-scope="account"
              label="Complemento:"
              class="col-md-6"
              name="account.complement"
              placeholder="Ex.: Apto 12"
              validate="min:4|max:30"
              type="text"
            />
            <div class="textbox mb-5">
              <label class="textbox__label">Motivo da solicitação de 2º via</label>
              <select v-model="reasonSelected" class="textbox__input" required>
                <option value="">Selecione o motivo da 2ª via</option>
                <option
                  :value="option"
                  v-for="option in selectOptions"
                  :key="option">
                  {{option}}
                </option>
              </select>
            </div>
          </div>
          <v-textbox
            v-if="reasonSelected=='Outro'"
            v-model="reason"
            data-vv-as="Descreva o motivo da solicitação da segunda via"
            label="Descreva o motivo da solicitação da segunda via"
            class="col-md-12 mb-5"
            placeholder="Ex.: Meu cartão quebrou, sumiu"
            validate="min:4|max:100"
            type="text"
          />
        </div>
        <div class="block__form__actions">
          <v-button
            label="Solicitar 2º Via"
            class="block__form__actions__submit"
            v-if="this.cardData.idStatusCartao != 1"
          />
          <a @click="close" class="block__form__actions__back"> Não, voltar </a>
        </div>
      </form>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.block {
  position: fixed 0 0 0 0;
  z-index: 9998;
  @media (min-width: 992px) {
    align-items: center;
    background-color: rgba(0, 11, 23, 0.5);
    display: flex;
    justify-content: center;
  }
  &-enter-active {
    transition: all 250ms ease-in;
  }
  &-leave-active {
    transition: all 250ms ease-out;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
    /* transform: translateX(100%);
    @media (min-width: 992px) {
      transform: translateY(-100%);
    } */
  }
  &__form {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    max-height: 90%;
    height: 90%;
    overflow: auto;
    justify-content: space-evenly;
    padding: 1.5rem;
    position: relative;
    @media (min-width: 992px) {
      align-items: center;
      border-radius: 0.5rem;
      height: auto;
      padding: 1.5rem 5rem;
      text-align: center;
      width: 38rem;
    }
    &__close {
      color: var(--color-grey-medium);
      position: absolute 1.5rem 1.5rem auto auto;
      size: 1rem;
      &__icon {
        display: block;
      }
    }
    &__icon {
      color: var(--color-black);
      size: 5rem;
    }
    &__title {
      color: var(--color-black);
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1.5rem 0;
      margin-top: 400px;
    }
    &__info {
      font-size: 0.875rem;
      text-align: left;
    }
    &__password {
      margin: 2rem 0;
    }
    &__actions {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      width: 100%;
      /* @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      } */
      &__submit {
        background-color: var(--color-red);
      }
      &__back {
        color: var(--color-black);
        display: block;
        margin-top: 1rem;
        padding: 1rem 0 0.75rem;
        /* @media (min-width: 992px) {
          margin-right: .75rem;
          margin-top: 2rem;
          width: 10rem;
        } */
      }
    }
  }
}
</style>
<style>
.textbox__input {
  border-bottom: 1px #0000008c solid;
}
#app .message--error {
  z-index: 9999!important;
}
</style>
