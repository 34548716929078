var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block" }, [
    _c(
      "form",
      {
        staticClass: "block__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.changePass.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "a",
          { staticClass: "block__form__close", on: { click: _vm.close } },
          [
            _c("v-icon", {
              staticClass: "block__form__close__icon",
              attrs: { name: "close" },
            }),
          ],
          1
        ),
        _c("h1", { staticClass: "block__form__title" }, [
          _vm._v("Alterar senha do Cartão?"),
        ]),
        _c("p", { staticClass: "block__form__info" }, [
          _vm._v(
            "Informe a senha de acesso ao site para criar uma nova senha para seu cartão."
          ),
        ]),
        this.idProduto === 9 || this.idProduto === 10
          ? _c("p", { staticClass: "block__form__info" }, [
              _vm._v(
                "Ao alterar a senha do cartão, realize o desbloqueio para utilizá-lo"
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("v-textbox", {
                staticClass: "block__form__password",
                attrs: {
                  label: "Insira a senha de acesso ao site",
                  name: "password",
                  placeholder: "••••••••",
                  required: "",
                  type: "password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("v-textbox", {
                staticClass: "block__form__password",
                attrs: {
                  label: "Insira o número do cartão",
                  name: "password",
                  placeholder: "•••• •••• •••• ••••",
                  required: "",
                  mask: "#### #### #### ####",
                  type: "text",
                },
                model: {
                  value: _vm.cardNumber,
                  callback: function ($$v) {
                    _vm.cardNumber = $$v
                  },
                  expression: "cardNumber",
                },
              }),
            ],
            1
          ),
        ]),
        _c("v-textbox", {
          staticClass: "block__form__password",
          attrs: {
            label: "Crie a nova senha para seu cartão (Deve conter 4 números)",
            name: "password",
            maxlength: "4",
            placeholder: "••••",
            required: "",
            type: "password",
          },
          model: {
            value: _vm.cardPassword,
            callback: function ($$v) {
              _vm.cardPassword = $$v
            },
            expression: "cardPassword",
          },
        }),
        _c(
          "div",
          { staticClass: "block__form__actions" },
          [
            _c("v-button", {
              staticClass: "block__form__actions__submit",
              attrs: { label: "Alterar Senha" },
            }),
            _c(
              "a",
              {
                staticClass: "block__form__actions__back",
                on: { click: _vm.close },
              },
              [_vm._v("\n        Não, voltar\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }